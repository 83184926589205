import { hot } from 'react-hot-loader/root';
import React from 'react';
import { usePlanSelectionFeatureGate } from '../../../hooks/usePlanSelectionFeatureGate';
import styles from "./CnameFooter.css";

interface CnameFooterProps {
  entriDomainEnabled: boolean;
  settingsPagesDomain: string;
}

const CnameFooter = ({
  entriDomainEnabled,
  settingsPagesDomain
}: CnameFooterProps) => {

  const gatedSubscriptionPrompt = `Please upgrade to&nbsp<strong>Experiment</strong>&nbspor&nbsp<strong>Optimize</strong>&nbspfor higher user and domain limits!`;

  const urlToDomainGuide = 'https://documentation.unbounce.com/hc/en-us/articles/203661044-Connecting-Your-Domain-to-Unbounce';

  const handleAddDomainClick = () => {
    // if the upgrade modal flow logic changes in billing-ui and we need to trigger this flow
    // revisit logic in app/views/domains/index.html.haml to fully replace haml with react component
  };

  const gatedDomainUpgradeHandler = usePlanSelectionFeatureGate({
    gatedFunction: handleAddDomainClick,
    customizations: {
      prompt: gatedSubscriptionPrompt
    }
  });

  return (
    <div className={styles.cnameFooter}>
      {entriDomainEnabled ?
        <>
          <span>Need help setting up a domain? </span>
          <span>
            <a className={styles.link} target="_blank" href={urlToDomainGuide}>Follow our handy guide </a>
            or
            <a onClick={gatedDomainUpgradeHandler} className={styles.link}> try connecting a domain manually</a>
          </span>
        </>
        :
        <>
          <span>All Custom Domains should have a CNAME pointing to <strong>{settingsPagesDomain}</strong>.{' '}</span>
          <span>If you have any questions about setting up your Custom Domains, please try setting one up by{' '}</span>
          <a className={styles.link} target="_blank" href={urlToDomainGuide}>
            <strong>
              following our handy guide.
            </strong>
          </a>
        </>
      }
    </div>
  )
};

export default hot(CnameFooter);
